<template>
  <div id="game_page" class="xs-container">
    <section class="ingame-section xs-section">
      <div class="ingame-inner">
        <div class="ingame-box">
          <div
            class="ingame-box-inner"
            :class="platFormType === false ? '' : 'mobile'"
          >
            <div class="left-background"></div>
            <div class="ingame_container" ref="ingameContainer">
              <iframe
                ref="gameFrame"
                scrolling="no"
                id="iframe"
                :src="`https://xgame.glaze.live/${
                  platFormType === false ? 'desktop' : 'mobile'
                }/`"
                allow="fullscreen"
              ></iframe>
            </div>
            <div class="right-background"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import { MachineNamespace, MachineActions } from "@/store/machine";

export default {
  props: {},
  data() {
    const params = new URL(document.location).searchParams;

    return {
      platFormType: false, // [ false = Desktop, true = Mobile ]
      machineId: params.get("id"),
      playerId: params.get("playerId"),
      playerToken: params.get("externalToken")
    };
  },

  computed: {},

  beforeDestroy() {
    // iframe message 리스너 제거
    window.removeEventListener("message", this.receiveMessage);
    window.removeEventListener("resize", this.resizeIframe);
  },

  // 브라우저 뒤로가기 버튼 클릭시 호출 이벤트
  beforeRouteLeave(to, from, next) {
    next(false);
    return "";
  },

  mounted() {
    // iframe message 리스너 추가
    window.addEventListener("message", this.receiveMessage);

    // 플랫폼 확인 (데스크톱, 모바일)
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.platFormType = true; // 모바일 플랫폼
    }

    // 초기 호출
    this.resizeIframe();

    // 브라우저 크기 변경 시 호출
    window.addEventListener("resize", this.resizeIframe);
  },

  methods: {
    ...mapActions(MachineNamespace, {
      // ACTION_CREATE_MACHINE_PLAYER: MachineActions.ACTION_CREATE_MACHINE_PLAYER,
      ACTION_DELETE_MACHINE_PLAYER: MachineActions.ACTION_DELETE_MACHINE_PLAYER
    }),

    // 게임 클라이언트로부터 전달받은 준비완료 메시지
    receiveMessage(e) {
      this.player_ban = false;

      switch (e.data.functionName) {
        case "ReceiveGameState":
          e.source.postMessage(
            {
              functionName: "ReceiveGameState",
              data: {
                token: this.playerToken,
                machine_id: this.machineId,
                currency: "PHP"
              }
            },
            "*"
          );
          break;
        case "MoveToMain":
          this.closeGame();
          break;
        case "BanToMain":
          this.closeGame();
          break;
        default:
          break;
      }
    },

    closeGame() {
      const machine_id = this.machineId;
      const player_id = this.playerId;
      const external_token = this.playerToken;

      // 선택한 머신에 사용자 정보 삭제
      this.ACTION_DELETE_MACHINE_PLAYER({
        machine_id,
        player_id,
        external_token
      }).then(res => {
        top.window.close();
      });
    },

    resizeIframe() {
      const container = this.$refs.ingameContainer.parentNode; // 부모 요소
      const aspectRatio = window.innerWidth / window.innerHeight;

      console.log(container);

      if (aspectRatio < 16 / 9) {
        // 16:9보다 좁은 경우
        container.classList.add("compact-layout");
        container.classList.remove("normal-layout");
      } else {
        // 16:9 이상인 경우
        container.classList.add("normal-layout");
        container.classList.remove("compact-layout");
      }
    },

    // iframe postMessage 전달
    sendPostMessage() {
      this.$refs.gameFrame.contentWindow.postMessage(
        { functionName: "OpenCreditOutPopup" },
        "*"
      );
    }
  }
};
</script>
