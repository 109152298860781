<template>
  <div id="game_page" class="xs-container">
    <section class="ingame-section xs-section">
      <div class="ingame-inner">
        <div class="ingame-box">
          <div
            class="ingame-box-inner"
            :class="platFormType === false ? '' : 'mobile'"
          >
            <div class="left-background"></div>
            <div class="ingame_container" ref="ingameContainer">
              <iframe
                ref="gameFrame"
                scrolling="no"
                id="iframe"
                :src="`https://xgame.glaze.live/${
                  platFormType === false ? 'desktop' : 'mobile'
                }/`"
                allow="fullscreen"
              ></iframe>
            </div>
            <div class="right-background"></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { AuthNamespace, AuthActions, AuthMutations } from "@/store/auth";
import {
  MachineNamespace,
  MachineActions,
  MachineMutations
} from "@/store/machine";

import EventBus from "@/utils/EventBus";

export default {
  components: {},
  props: {},
  data() {
    return {
      platFormType: false, // [ false = Desktop, true = Mobile ]
      denied: false,
      move_router_path: null,
      move_machine_id: null,
      dummy_machine_id_list: ["8abcab0b-bed0-4521-9804-cbea60e78d9e"],
      player_ban: false, // kick out 상태값
      fullScreen: true,
      aspectRatio: 16 / 9
    };
  },

  watch: {
    // 사용자 currency 정보 업데이트 observer
    getUserCurrency() {
      this.$refs.gameFrame.contentWindow.postMessage(
        {
          functionName: "GetCurrencyList",
          data: {
            code: this.getUserCurrency
          }
        },
        "*"
      );
    }
  },

  computed: {
    ...mapGetters(AuthNamespace, [
      "getExternalToken",
      "getPlayerId",
      "getUserCurrency",
      "getTokenExpiry"
    ]),

    ...mapGetters(MachineNamespace, ["getMachineId"])
  },

  beforeDestroy() {
    // iframe message 리스너 제거
    window.removeEventListener("message", this.receiveMessage);
    // 컴포넌트가 파괴되기 전에 이벤트 리스너 제거
    window.removeEventListener("resize", this.resizeIframe);
  },

  // 브라우저 뒤로가기 버튼 클릭시 호출 이벤트
  beforeRouteLeave(to, from, next) {
    // console.log("beforeRouteLeave");
    this.movePageEvent();
    next(false);
    return "";
  },

  mounted() {
    EventBus.$on("movePageEvent", this.movePageEvent);

    // iframe message 리스너 추가
    window.addEventListener("message", this.receiveMessage);

    // 플랫폼 확인 (데스크톱, 모바일)
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      this.platFormType = true; // 모바일 플랫폼
    }

    this.changeFullSize();

    // 초기 호출
    this.resizeIframe();

    // 브라우저 크기 변경 시 호출
    window.addEventListener("resize", this.resizeIframe);
  },

  methods: {
    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGOUT: AuthActions.ACTION_AUTH_LOGOUT
    }),

    ...mapMutations(AuthNamespace, {
      MUTATION_UPDATE_AFK_INFO: AuthMutations.MUTATION_UPDATE_AFK_INFO
    }),

    ...mapActions(MachineNamespace, {
      ACTION_CREATE_MACHINE_PLAYER: MachineActions.ACTION_CREATE_MACHINE_PLAYER,
      ACTION_DELETE_MACHINE_PLAYER: MachineActions.ACTION_DELETE_MACHINE_PLAYER
    }),

    ...mapMutations(MachineNamespace, {
      MUTATION_MACHINE_KICK_OFF: MachineMutations.MUTATION_MACHINE_KICK_OFF,
      MUTATION_USER_MACHINE: MachineMutations.MUTATION_USER_MACHINE
    }),

    // 게임 클라이언트로부터 전달받은 준비완료 메시지
    receiveMessage(e) {
      this.player_ban = false;

      switch (e.data.functionName) {
        case "ReceiveGameState":
          e.source.postMessage(
            {
              functionName: "ReceiveGameState",
              data: {
                token: this.getExternalToken,
                machine_id: this.getMachineId,
                currency: this.getUserCurrency
              }
            },
            "*"
          );
          break;

        case "MoveToMain":
          this.closeGame();
          break;

        case "KickToMain":
          this.MUTATION_MACHINE_KICK_OFF(true);
          this.closeGame();
          break;

        // AFK Kicked Out
        case "KickToMainAfk":
          this.MUTATION_UPDATE_AFK_INFO(
            e.data.params && e.data.params.length > 0 ? e.data.params[0] : 0
          );
          this.closeGame();
          break;

        case "BanToMain":
          this.player_ban = true;
          this.closeGame();
          break;

        case "requestFullScreen":
          // 전체화면 설정
          this.fullScreen = true;
          this.changeFullSize();
          break;

        case "exitFullScreen":
          // 전체화면 해제
          this.fullScreen = false;
          this.changeFullSize();
          break;
        default:
          break;
      }
    },

    // 게임내에서 페이지 이동 이벤트 리스너
    moveGameEvent(id) {
      let xsMain = document.getElementById("xs_main");
      xsMain.scrollTo(0, 0);
      // 게임 이동 판별 값 업데이트
      this.move_machine_id = id;
      this.sendPostMessage();
    },

    changeFullSize() {
      const newTemplate = `
      "main"
      "main"
      "main"
    `;
      const main = document.getElementById("x-slot");
      const header = document.getElementById("xs_header");
      const footer = document.getElementById("xs_footer");

      main.style.gridTemplateAreas = newTemplate;
      main.style.gridTemplateRows = "1fr";
      main.style.padding = "0";

      header.style.display = "none";
      footer.style.display = "none";
    },

    // 페이지 이동 이벤트 리스너
    movePageEvent(path) {
      let xsMain = document.getElementById("xs_main");
      xsMain.scrollTo(0, 0);
      this.move_router_path = path;
      this.sendPostMessage();
    },

    // iframe postMessage 전달
    sendPostMessage() {
      this.$refs.gameFrame.contentWindow.postMessage(
        { functionName: "OpenCreditOutPopup" },
        "*"
      );
    },

    // 게임 종료
    async closeGame() {
      if (new Date(this.getTokenExpiry) < new Date()) {
        alert("Your login session was expired.");
        this.ACTION_AUTH_LOGOUT();
        this.moveRouterPath("/");
        return;
      }

      if (this.player_ban) {
        this.moveRouterPath("/");
        return;
      }

      const machine_id = this.getMachineId;
      const player_id = this.getPlayerId;
      const external_token = this.getExternalToken;

      // 선택한 머신에 사용자 정보 삭제
      this.ACTION_DELETE_MACHINE_PLAYER({
        machine_id,
        player_id,
        external_token
      }).then(res => {
        if (res.status == 200 || res.status === 404) {
          // 머신 ID가 존재하고 플레이어 Kick Off가 아닌 경우
          if (this.move_machine_id !== null) {
            this.$refs.gameGroupForm.createMachinePlayer(this.move_machine_id);
            return;
          }

          let replace_path = "/";
          switch (this.move_router_path) {
            // 로그아웃
            case "logout":
              this.ACTION_AUTH_LOGOUT();
              break;
            // all-game 이동
            case "/all-games":
              replace_path = "/all-games";
              break;
            default:
              break;
          }

          this.moveRouterPath(replace_path);
        }
      });
    },

    moveRouterPath(path) {
      this.MUTATION_USER_MACHINE(undefined); // 머신 ID 제거

      window.location.replace(path);
    },

    resizeIframe() {
      const container = this.$refs.ingameContainer.parentNode; // 부모 요소
      const aspectRatio = window.innerWidth / window.innerHeight;

      if (aspectRatio < 16 / 9) {
        // 16:9보다 좁은 경우
        container.classList.add("compact-layout");
        container.classList.remove("normal-layout");
      } else {
        // 16:9 이상인 경우
        container.classList.add("normal-layout");
        container.classList.remove("compact-layout");
      }
    }
  }
};
</script>
