// import { UserType } from "@/shared/enums";
import { authApi } from "@/api";
import VueCookies from "vue-cookies";

const AuthNamespace = "auth";

const AuthMutations = {
  MUTATION_DUPLICATE_USER: "MUTATION_DUPLICATE_USER", // 사용자 중복 로그인
  MUTATION_UPDATE_AFK_INFO: "MUTATION_UPDATE_AFK_INFO", // AFK 알림 유형 업데이트
  MUTATION_USER_INFO: "MUTATION_USER_INFO", // 사용자 정보 업데이트,
  MUTATION_UPDATE_CREDIT: "MUTATION_UPDATE_CREDIT", // 크레딧 정보 업데이트
  MUTATION_UPDATE_CURRENCY: "MUTATION_UPDATE_CURRENCY", // 커런시 정보 업데이트
  MUTATION_UPDATE_LOCALIZATION: "MUTATION_UPDATE_LOCALIZATION", // 현지화 정보 업데이트
  MUTATION_AUTH_LOGOUT: "MUTATION_AUTH_LOGOUT", // 로그아웃 상태
  MUTATION_UPDATE_QUICK_BUTTON_POINT: "MUTATION_UPDATE_QUICK_BUTTON_POINT"
};

const AuthActions = {
  ACTION_AUTH_LOGIN: "ACTION_AUTH_LOGIN", // 로그인 요청
  ACTION_AUTH_LOGOUT: "ACTION_AUTH_LOGOUT", // 로그아웃 요청
  ACTION_PLAYER_CREDIT: "ACTION_PLAYER_CREDIT", // 크레딧 정보 요청,
  ACTION_PLAYER_CURRENCY: "ACTION_PLAYER_CURRENCY", // 커런시 정보 요청,
  ACTION_UPDATE_PLAYER_CURRENCY: "ACTION_UPDATE_PLAYER_CURRENCY" // 커런시 정보 업데이트 요청,
};

const createInitialState = () => ({
  duplicateLogin: false, // 중복 로그인 여부
  afkKickedTime: null, // AFK 메시지 수신
  player_id: undefined,
  external_token: undefined,
  token_expiry: undefined,
  credit: 0,
  localization: "en",
  currency: null,
  is_login: false,
  quick_btn_point: {
    x: 0,
    y: 0
  }
});

const getters = {
  getDuplicateLogin(state) {
    return state.duplicateLogin;
  },
  getAfkData(state) {
    return state.afkKickedTime;
  },
  getPlayerId(state) {
    return state.player_id;
  },
  getExternalToken(state) {
    return state.external_token;
  },
  getUserCredit(state) {
    return state.credit;
  },
  getUserCurrency(state) {
    return state.currency;
  },
  getLoginCheck(state) {
    return state.is_login;
  },
  getQuickButtonPoint(state) {
    return state.quick_btn_point;
  },
  getLocalization(state) {
    return state.localization;
  },
  getTokenExpiry(state) {
    return state.token_expiry;
  }
};

const mutations = {
  /**
   * 사용자 중복 로그인
   */
  [AuthMutations.MUTATION_DUPLICATE_USER](state) {
    state.duplicateLogin = true;
  },

  /**
   * AFK Kicked Time 업데이트
   */
  [AuthMutations.MUTATION_UPDATE_AFK_INFO](state, res) {
    state.afkKickedTime = res;
  },

  /**
   * 사용자 정보 업데이트
   * @param {*} state
   * @param {*} res playerId, externalToken
   */
  [AuthMutations.MUTATION_USER_INFO](state, res) {
    state.is_login = true;
    state.player_id = res.playerId;
    state.external_token = res.externalToken;

    if (res.expiresIn) {
      var tokenExpiry = new Date();
      tokenExpiry.setSeconds(tokenExpiry.getSeconds() + res.expiresIn);
      state.token_expiry = tokenExpiry;
    }
  },

  /**
   * 크레딧 정보 업데이트
   */
  [AuthMutations.MUTATION_UPDATE_CREDIT](state, res) {
    state.credit = res.credit;
  },

  /**
   * 커런시 정보 업데이트
   */
  [AuthMutations.MUTATION_UPDATE_CURRENCY](state, res) {
    if (res !== null) {
      state.currency = res;
    } else {
      state.currency = "PHP";
    }
  },

  /**
   * 현지화 정보 업데이트
   */
  [AuthMutations.MUTATION_UPDATE_LOCALIZATION](state, res) {
    state.localization = res;
  },

  /**
   * 로그아웃 상태
   */
  [AuthMutations.MUTATION_AUTH_LOGOUT](state) {
    state.is_login = false;
    state.player_id = undefined;
    state.external_token = undefined;
    state.credit = 0;
    state.currency = null;
    state.duplicateLogin = false;
  },

  /**
   * 퀵 조인 버튼 위치 정보 업데이트
   */
  [AuthMutations.MUTATION_UPDATE_QUICK_BUTTON_POINT](state, res) {
    state.quick_btn_point.x = res.x;
    state.quick_btn_point.y = res.y;
  }
};

const actions = {
  // 사용자 로그인
  async [AuthActions.ACTION_AUTH_LOGIN](context, payload) {
    const { commit, dispatch } = context;

    const { user_id, password } = payload;
    let url = undefined;

    await authApi
      .requestUserLogin(user_id, password)
      .then(async res => {
        url = res.url;

        // Return URL 파싱
        const parse_url = new URL(res.url).searchParams;
        const playerId = parse_url.get("playerId");
        const externalToken = parse_url.get("externalToken");

        const result = {
          playerId: playerId,
          externalToken: externalToken,
          expiresIn: res.expiresIn
        };
        // 사용자 정보 업데이트
        commit("MUTATION_USER_INFO", result);

        // 사용자 크레딧 정보 요청
        await dispatch(AuthActions.ACTION_PLAYER_CREDIT, result);
        // 사용자 커런시 정보 요청
        await dispatch(AuthActions.ACTION_PLAYER_CURRENCY, playerId);

        VueCookies.set("xs", true, "");
      })
      .catch(() => {
        url = undefined;
      });

    return url;
  },

  // 사용자 로그아웃 요청
  async [AuthActions.ACTION_AUTH_LOGOUT](context) {
    const { commit } = context;
    commit("MUTATION_AUTH_LOGOUT");
    VueCookies.remove("xs");
  },

  // 사용자 크레딧 정보 요청
  async [AuthActions.ACTION_PLAYER_CREDIT](context, payload) {
    const { commit, state, dispatch } = context;
    const { playerId } = payload;

    let result = await authApi.requestUserCredit(playerId).then(res => {
      commit("MUTATION_UPDATE_CREDIT", res.data);
      return res;
    });

    /**
     * 20240703 jpchoi - imsi : 사용자 currency 요청
     * 로그인 상태에서 2번 호출
     * 쿼리 스트링만을 가지고 다른 브라우저 로그인시 3번 호출
     */

    if (state.currency == null) {
      await dispatch(AuthActions.ACTION_PLAYER_CURRENCY, playerId);
    }

    return result;
  },

  // 사용자 커런시 정보 요청
  async [AuthActions.ACTION_PLAYER_CURRENCY](context, payload) {
    const { commit, dispatch } = context;
    const playerId = payload;

    let result = await authApi.requestUserCurrency(playerId).then(res => {
      commit("MUTATION_UPDATE_CURRENCY", res.data);
      return res.data;
    });

    if (result !== null) {
      return result;
    } else {
      // currency 정보가 null일 경우 기본 화폐 단위로 업데이트
      const currencyInfo = {
        playerId: playerId,
        currency: "PHP"
      };
      await dispatch(AuthActions.ACTION_UPDATE_PLAYER_CURRENCY, currencyInfo);
    }
  },

  //사용자 커런시 정보 업데이트 요청
  async [AuthActions.ACTION_UPDATE_PLAYER_CURRENCY](context, payload) {
    const { commit } = context;
    const { playerId, currency } = payload;

    let result = await authApi
      .updateUserCurrency(playerId, currency)
      .then(res => {
        commit("MUTATION_UPDATE_CURRENCY", currency);
        return res;
      });
    return result;
  }
};

export { AuthNamespace, AuthMutations, AuthActions };

export default {
  state: () => createInitialState(),
  getters,
  mutations,
  actions,
  namespaced: true
};
