<template>
  <header class="slot-header" id="xs_header">
    <div class="header-inner">
      <div class="header-logo-box">
        <div class="logo-inner">
          <div class="header-logo">
            <a @click="mainLogoclick">
              <img src="@/assets/img/header/header-logo.png" alt="xslot-logo" />
            </a>
          </div>
        </div>
      </div>
      <div class="wallet" :class="getLoginCheck ? 'active' : ''">
        <div class="wallet-total">
          <p>₱</p>
          <p>{{ calcUserCredit }}</p>
        </div>
      </div>
      <div class="header-navi">
        <div class="navi-inner">
          <div class="navi-login" v-if="getLoginCheck">
            <div
              class="navi-menu login profile"
              :class="profileDialog ? 'active' : ''"
            >
              <button
                class="profile-btn"
                @click="profileDialog = !profileDialog"
              >
                <img
                  v-if="profileDialog"
                  src="@assets/img/x_btn.png"
                  alt="closeprofile"
                />
                <img
                  v-else
                  src="@assets/img/login/profile_icon01.png"
                  alt="profile"
                />
              </button>
              <div class="profile-container">
                <div class="overlay" @click="profileDialog = false"></div>
                <div class="profile-dialog">
                  <ProfileDialog
                    v-if="profileDialog"
                    @closeDialog="closeProfileDialog"
                  />
                </div>
              </div>
            </div>
            <!-- <div class="navi-menu login">
              <button class="like-btn"></button>
            </div> -->
            <div class="navi-menu login alarm">
              <button
                class="alarm-btn"
                data-count="99"
                @click="alarmDialog = !alarmDialog"
                :class="alarmDialog ? 'active' : ''"
              ></button>
              <div class="alarm-container" :class="alarmDialog ? 'active' : ''">
                <div class="overlay" @click="alarmDialog = !alarmDialog"></div>
                <div class="alarm-pop">
                  <div class="alarm-inner">
                    <v-tabs v-model="tab" background-color="transparent">
                      <v-tab
                        v-for="item in alarmItems"
                        :key="item"
                        :disabled="item === 'Unread'"
                      >
                        {{ item }}
                        <div class="alarm-count">
                          <p data-count="99"></p>
                        </div>
                      </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                      <v-tab-item :key="alarmItems.All">
                        <div class="alarm-box">
                          <div class="alarm-inner">
                            <ul class="alarm-lists">
                              <li class="alarm-list">
                                <button class="delete-icon">
                                  <i></i>
                                </button>
                                <div class="alarm-area">
                                  <p class="alarm-date">11/27/2023 08:45</p>
                                  <h2>Game Maintenance</h2>
                                  <div class="list-info">
                                    <div class="list-img">
                                      <img
                                        src="@assets/img/notice_img00.png"
                                        alt=""
                                      />
                                    </div>
                                    <div class="list-text">
                                      <div class="notice-period mb-3">
                                        <p class="notice-date">
                                          2023/11/27 08:00 ~ 14:00
                                        </p>
                                        <p class="notice-caption">
                                          88 FORTUNES,DIAMOND ETERNITY, 5
                                          TREASURES,FLOWER OF RICHES,DANCING
                                          DRUMS, DOUBLE BLESSING (all EFDC
                                          games)
                                        </p>
                                      </div>
                                      <div class="notice-period">
                                        <p class="notice-date">
                                          2023/11/27 08:00 ~ 14:00
                                        </p>
                                        <p class="notice-caption">
                                          Machine number -
                                          0001,0022,0047,0061,0075,0099
                                        </p>
                                      </div>
                                      <div class="notice-exp">
                                        <p>
                                          During the upgrade time, the above X
                                          SLOT machines are not able to play.
                                        </p>
                                        <p>
                                          We strongly recommend you to play
                                          other games on ouyr platform
                                        </p>
                                        <p>Thank you for choosing the XSLOT!</p>
                                        <p>The XSLOT Team</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="fold-area">
                                  <v-btn class="fold-btn">
                                    Close <i></i>
                                  </v-btn>
                                </div>
                              </li>
                              <li class="alarm-list">
                                <div class="alarm-area">
                                  <p class="alarm-date">11/27/2023 08:45</p>
                                  <h2>Game Maintenance</h2>
                                  <div class="list-info">
                                    <div class="list-img">
                                      <img
                                        src="@assets/img/notice_img01.png"
                                        alt=""
                                      />
                                    </div>
                                    <div class="list-text">
                                      <div class="notice-period mb-3">
                                        <p class="notice-date">
                                          2023/11/27 08:00 ~ 14:00
                                        </p>
                                        <p class="notice-caption">
                                          88 FORTUNES,DIAMOND ETERNITY, 5
                                          TREASURES,FLOWER OF RICHES,DANCING
                                          DRUMS, DOUBLE BLESSING (all EFDC
                                          games)
                                        </p>
                                      </div>
                                      <div class="notice-period">
                                        <p class="notice-date">
                                          2023/11/27 08:00 ~ 14:00
                                        </p>
                                        <p class="notice-caption">
                                          Machine number -
                                          0001,0022,0047,0061,0075,0099
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <button class="delete-icon">
                                  <i></i>
                                </button>
                                <div class="fold-area">
                                  <v-btn class="fold-btn">
                                    View all <i></i>
                                  </v-btn>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </v-tab-item>
                      <v-tab-item :key="alarmItems.Unread"> </v-tab-item>
                    </v-tabs-items>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="navi-login" v-else>
            <div class="navi-menu">
              <v-btn class="signin-btn" text @click="signInDialog = true"
                >{{ $t("signIn.signInTitle") }}
              </v-btn>
            </div>
            <div class="navi-menu">
              <!-- 회원가입 숨김 -->
              <!-- <v-btn class="signup-btn" @click="openSignUpDialog()">
                <span>Sign Up</span>
                
              </v-btn> -->
            </div>
          </div>
          <!-- TODO: bmm 인증을 위한 숨김 처리 -->
          <div class="navi-menu lang-select">
            <div
              class="select-box"
              :class="isAcive ? 'active' : ''"
              @click="selectLang()"
            >
              <div class="selected-icon" :class="isAcive ? 'active' : ''">
                <i class="globe"></i>
              </div>
              <ul class="selected-option">
                <li class="option" @click="changeLocale('kr')">
                  <div class="option-inner">
                    <img src="@/assets/img/flag_kr.png" alt="korea" />
                    <span>한국어</span>
                  </div>
                </li>
                <li class="option" @click="changeLocale('en')">
                  <div class="option-inner">
                    <img src="@/assets/img/flag_usa.png" alt="USA" />
                    <span>English</span>
                  </div>
                </li>
                <li class="option" @click="changeLocale('cn')">
                  <div class="option-inner">
                    <img src="@/assets/img/flag_cn.png" alt="China" />
                    <span>简体中文</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-dialog
      id="signin_modal"
      v-if="signInDialog"
      v-model="signInDialog"
      activator="parent"
      width="auto"
    >
      <SignInDialog @closeDialog="closeSignInDialog" />
    </v-dialog>
    <!-- <v-dialog
      v-if="signUpDialog"
      v-model="signUpDialog"
      activator="parent"
      width="auto"
      content-class="signup_modal"
    >
      <SignUpDialog @closeDialog="closeSignUpDialog" />
    </v-dialog> -->
    <v-dialog
      content-class="vertical"
      v-if="walletDialog"
      v-model="walletDialog"
      activator="parent"
      width="auto"
    >
      <WalletDialog @closeDialog="closeWalletDialog" />
    </v-dialog>
    <!-- <v-dialog
      content-class="profile-dialog"
      v-if="profileDialog"
      v-model="profileDialog"
      activator="parent"
      width="unset"
    >
      <ProfileDialog
        :class="isOpen ? 'menu-open' : ''"
        @closeDialog="closeProfileDialog"
      />
      <div class="overlay" @click="closeProfileDialog()"></div>
    </v-dialog> -->
  </header>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { AuthNamespace, AuthActions, AuthMutations } from "@/store/auth";
import { MachineNamespace, MachineMutations } from "@/store/machine";

import EventBus from "@/utils/EventBus";
import SignInDialog from "../common/dialog/SignInDialog.vue";
//import SignUpDialog from "../common/dialog/SignUpDialog.vue";
import WalletDialog from "../common/dialog/WalletDialog.vue";
import ProfileDialog from "../common/dialog/ProfileDialog.vue";

import VueCookies from "vue-cookies";

export default {
  components: { SignInDialog, WalletDialog, ProfileDialog },
  data() {
    return {
      isAcive: false,
      signInDialog: false, // 로그인 dialog
      //signUpDialog: false,    // 회원가입 dialog
      profileDialog: false,
      alarmDialog: false,
      walletDialog: false,
      tab: null,
      tabs: null,
      alarmItems: ["All", "Unread"],
      walletItems: ["Deposit", "Withdraw"],
      walletTabs: ["Fiat", "Crypto", "CreditCard"],
      selectDollar: [
        {
          name: "KRW",
          image: "flag_kr.png"
        },
        {
          name: "USD",
          image: "flag_usa.png"
        }
      ],
      dollar: "krw",
      emailField: "",
      emailVerify: "",
      mobileField: "",
      mobile: null,
      sender: "",
      ws: null
    };
  },

  computed: {
    ...mapGetters(AuthNamespace, [
      "getPlayerId",
      "getExternalToken",
      "getUserCredit",
      "getLoginCheck",
      "getLocalization"
    ]),

    calcUserCredit() {
      return Number(this.getUserCredit).toLocaleString();
    }
  },

  mounted() {
    this.$i18n.locale = this.getLocalization;
    // 로그인 상태인 경우 크레딧 요청
    if (this.getLoginCheck) {
      this.requestCreditInfo();

      this.ws = new WebSocket(
        `${process.env.VUE_APP_API_URL.replace("http://", "ws://").replace(
          "https://",
          "wss://"
        )}/ws/client/player?token=${this.getExternalToken}`
      );
      this.ws.addEventListener("message", event => {
        if (event.data === "ping") {
          this.ws.send("pong");
        }
      });
    }
  },

  methods: {
    ...mapMutations(AuthNamespace, {
      MUTATION_UPDATE_CREDIT: AuthMutations.MUTATION_UPDATE_CREDIT,
      MUTATION_UPDATE_LOCALIZATION: AuthMutations.MUTATION_UPDATE_LOCALIZATION
    }),

    ...mapMutations(MachineNamespace, {
      MUTATION_USER_MACHINE: MachineMutations.MUTATION_USER_MACHINE
    }),

    ...mapActions(AuthNamespace, {
      ACTION_AUTH_LOGIN: AuthActions.ACTION_AUTH_LOGIN,
      ACTION_PLAYER_CREDIT: AuthActions.ACTION_PLAYER_CREDIT,
      ACTION_AUTH_LOGOUT: AuthActions.ACTION_AUTH_LOGOUT
    }),

    // 크레딧 정보 요청
    async requestCreditInfo() {
      await this.ACTION_PLAYER_CREDIT({
        playerId: this.getPlayerId,
        externalToken: this.getExternalToken
      }).then(res => {
        // token이 만료되어 status가 200이 아닌 경우
        // 쿠키 정보가 없는 경우
        if (res.status !== 200 || !VueCookies.isKey("xs")) {
          this.ACTION_AUTH_LOGOUT();
          this.MUTATION_USER_MACHINE(undefined);
          window.location.replace("");
        }
      });
    },

    // 로그인 버튼 클릭 이벤트
    async handleLogin() {
      const validate = this.$refs.form.validate();

      if (validate) {
        await this.ACTION_AUTH_LOGIN({
          user_id: this.idField,
          password: this.pwdField
        }).then(async res => {
          // res = [ redirect URL 정보 ]
          // url 정보가 undefined 인 경우
          if (res !== undefined) {
            const data = {
              playerId: this.getPlayerId,
              externalToken: this.getExternalToken
            };

            if (process.env.NODE_ENV === "local") {
              // window.location.replace(
              //   `http://localhost:8080/?playerId=${this.getPlayerId}&externalToken=${this.getExternalToken}`
              // );

              this.$router.replace({ name: "main", query: data });
            } else {
              // window.location.replace(res);

              this.$router.replace({ name: "main", query: data });
            }
          }
        });
      }
    },
    changeLocale(locale) {
      this.MUTATION_UPDATE_LOCALIZATION(locale);
      this.$i18n.locale = this.getLocalization;
    },
    selectLang() {
      this.isAcive = !this.isAcive;
    },

    // 메인 로고 클릭
    mainLogoclick() {
      switch (window.location.pathname) {
        // 현재 게임 페이지인 경우
        case "/game":
          EventBus.$emit("movePageEvent", "/");
          break;
        // 현재 메인 페이지인 경우
        case "/":
          window.location.reload();
          break;
        default:
          this.$router.push("/").catch(() => {});
          break;
      }
    },
    // sign in modal 제어
    openSignInDialog() {
      this.signInDialog = true;
    },
    closeSignInDialog() {
      this.signInDialog = false;
    },
    // sign up modal 제어
    openSignUpDialog() {
      this.signUpDialog = true;
    },
    closeSignUpDialog() {
      this.signUpDialog = false;
    },
    // wallet modal 제어
    openWalletDialog() {
      this.walletDialog = true;
    },
    closeWalletDialog() {
      this.walletDialog = false;
    },
    // profile modal 제어
    openProfileDialog() {
      this.profileDialog = true;
    },
    closeProfileDialog() {
      this.profileDialog = false;
    }
  }
};
</script>
