<template>
  <section class="slot-jackpot xs-section">
    <div class="grid-container">
      <div class="all-games">
        <v-btn class="all-btn" @click="selectJackpot(null)">
          <img src="@assets/img/jackpot/all-jackpot.png" alt="all-games" />
        </v-btn>
      </div>
      <swiper class="swiper jackpot-swiper" :options="jackpotSwiperOption">
        <swiper-slide
          class="slot-slide swiper-slide"
          v-for="list in jackpotList"
          :key="list.Id"
        >
          <div class="jackpot-box" @click="selectJackpot(list.Id)">
            <div class="jackpot-total">
              <v-icon></v-icon>
              <p>1.4k</p>
            </div>
            <img
              :src="list.ImagePath"
              :alt="list.Name"
              @error="onImageError($event)"
            />
            <div class="jackpot-num" v-if="list.level">
              <p class="jackpot-number">
                <span class="dollar">$</span>
                <span class="jackpot-scroll">
                  <span
                    v-for="(digit, index) in digits"
                    :key="index"
                    class="digit"
                    :style="digit.style"
                  >
                    <span>{{ digit.value }}</span>
                  </span>
                </span>
              </p>
            </div>

            <div class="jackpot-alone" v-else>
              <p>STAND ALONE</p>
            </div>
          </div>
        </swiper-slide>

        <div class="swiper-button-prev swiper-button" slot="button-prev"></div>
        <div class="swiper-button-next swiper-button" slot="button-next"></div>
      </swiper>
    </div>
  </section>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";

import { mapActions, mapGetters } from "vuex";
import { JackpotNamespace, JackpotActions } from "@/store/jackpot";

export default {
  name: "JackpotSection",
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      jackpotSwiperOption: {
        slidesPerView: 2,
        spaceBetween: 3,
        breakpoints: {
          480: {
            slidesPerView: 3, // 480px ~ 768px 미만에서는 3개
            spaceBetween: 3 // (선택) 간격 조정
          },
          1024: {
            slidesPerView: 4, // 1024px 이상에서는 4개
            spaceBetween: 3 // (선택) 간격 조정
          }
        },
        loop: true,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        }
      },
      defaultJackpotImage: require("@assets/img/jackpot/jackpot01.png"),
      targetAmount: 100000.05, // 목표 금액
      digits: [] // 각 자리수 배열
    };
  },
  mounted() {
    this.ACTION_READ_JACKPOT_LIST();
    this.splitDigits();
    this.animateDigits();
  },
  computed: {
    ...mapGetters(JackpotNamespace, ["getJackpotList"]),
    jackpotList() {
      return this.getJackpotList || []; // 잭팟 리스트가 없을 때 빈 배열 반환
    }
  },
  methods: {
    ...mapActions(JackpotNamespace, {
      ACTION_READ_JACKPOT_LIST: JackpotActions.ACTION_READ_JACKPOT_LIST
    }),
    selectJackpot(jackpotId) {
      this.$emit("selectJackpot", jackpotId); // 선택한 jackpotId를 상위 컴포넌트로 전달
    },
    onImageError(event) {
      event.target.src = this.defaultJackpotImage; // 이미지 로드 실패 시 기본 이미지 설정
    },
    splitDigits() {
      const amountStr = this.targetAmount.toFixed(2).toString();
      this.digits = amountStr.split("");
    },
    animateDigits() {
      let delay = 0;

      // 각 자리수에 대해 개별 애니메이션 딜레이 적용 (마지막 자리부터 첫 번째 자리까지)
      this.digits = this.digits
        .reverse() // 배열을 역순으로 뒤집기
        .map(digit => {
          const delayTime = `${delay}s`;
          delay += 0.3; // 각 자리마다 delay 증가
          return {
            value: digit,
            style: {
              animationDelay: delayTime
            }
          };
        })
        .reverse(); // 다시 원래 순서로 돌려놓기
    }
  }
};
</script>
