export class CustomWebSocket {
  constructor() {
    this.token = null;
    this.socket = null;
  }

  connect() {
    this.socket = new WebSocket(
      `ws://localhost:5198/ws/service/player/${this.token}`
    );

    // this.socket = new WebSocket(
    //   `wss://xapi.glaze.live/ws/client/player/${this.token}`
    // );

    this.socket.onmessage = function (event) {
      console.log(event.data);
    };
  }

  sendMessage(message) {
    this.socket.send(JSON.stringify(message));
  }

  disconnect() {
    this.socket.close();
  }
}
